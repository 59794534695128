<template>
  <div class="follow-card" :class="{skeleton: !state.maker.loaded}">
    <div class="top">
      <b class="title">친구 맺고 계속 응원하고 싶어요!</b>
    </div>
    <div class="inner">
      <div class="info">
        <Anchor :href="`/channel/${modalParams.memberSeq}?tabs=projects`">
          <MemberPicture :memberSeq="modalParams.memberSeq" size="40"></MemberPicture>
        </Anchor>
        <div class="text">
          <Anchor class="member-name" :href="`/channel/${modalParams.memberSeq}?tabs=projects`" :title="state.maker.info.memberName">{{ state.maker.info.memberName }}</Anchor>
          <span class="sub">팔로워 {{ state.maker.info.followers }}명</span>
        </div>
      </div>
      <div class="followed" v-if="modalParams.memberSeq !== $store.state.account.memberSeq">
        <button class="btn btn-sm" :class="$store.state.follows.memberSequences.find(m => m.makerSeq === modalParams.memberSeq) ? 'following' : 'follow'"
                :disabled="!modalParams.memberSeq || !$store.state.follows.loaded || !state.maker.loaded"
                @click="toggleFollow()">
                      <span :class="{'text-muted': $store.state.follows.memberSequences.find(m => m.makerSeq === modalParams.memberSeq)}">
                        {{ $store.state.follows.memberSequences.find(m => m.makerSeq === modalParams.memberSeq) ? "팔로우 취소" : "+ 팔로우" }}
                      </span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import MemberPicture from "@/components/MemberPicture";
import Anchor from "@/components/Anchor";
import store from "@/scripts/store";
import redirectCallback from "@/scripts/redirectCallback";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "modalFollow";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Anchor, MemberPicture},
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
      store.commit("setFollows");
      load();
    });

    const state = reactive({
      maker: {
        info: {
          memberName: "",
          followers: 0,
        },
        loaded: false
      }
    });

    const modalParams = store.getters.modalParams(component);

    const load = () => {
      state.maker.loaded = false;
      state.maker.info.memberName = "Wait a moment";

      http.get(`/api/channel/${modalParams.memberSeq}`).then(({data}) => {
        state.maker.loaded = true;
        state.maker.info = data.body;
      });
    };

    const toggleFollow = () => {
      const args = {makerSeq: modalParams.memberSeq};

      if (!store.state.account.loggedIn) {
        return redirectCallback.setAndLogin("toggleMemberFollow", args);
      }

      if (store.state.follows.memberSequences.find(m => m.makerSeq === modalParams.memberSeq)) {
        state.maker.info.followers -= 1;
      } else {
        state.maker.info.followers += 1;
      }

      redirectCallback.run("toggleMemberFollow", args);
    };

    return {component, state, modalParams, load, toggleFollow};
  }
});
</script>
<style lang="scss" scoped>
.follow-card {
  background: #fff;
  padding: $px25;
  min-height: $px100;

  .top {
    margin-bottom: $px25;

    .title {
      font-size: $px16;
      margin-bottom: $px5;
      display: block;
    }
  }

  .inner {
    border-radius: $px4;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .info {
      display: flex;
      align-items: center;
      overflow: hidden;

      .text {
        display: inline-block;
        padding: 0 $px13;
        overflow: hidden;

        > a {
          white-space: nowrap;
          display: block;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        .sub {
          color: $colorSecondary;
          display: inline-block;
          font-size: $px11;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          &:not(:first-of-type) {
            margin-left: $px5;
          }
        }
      }
    }

    .followed {
      > button {
        font-size: $px12;
        padding: $px8 $px10;
        text-align: center;
        width: $px90;
        white-space: nowrap;

        &.follow {
          background-color: $colorPurple;
          color: #fff;

          &:hover {
            background-color: $colorPurpleActive;
          }
        }

        &.following {
          background-color: #efefef;
          color: $colorSecondary;

          &:hover {
            background-color: $colorBackground;
          }
        }
      }
    }
  }

  &.skeleton {
    .top {
      .title, .desc {
        @include skeleton;
      }
    }

    .inner {
      .info {
        a, span, .text a {
          @include skeleton;
        }
      }

      .followed {
        .btn {
          &.followed, &.following, &.follow {
            @include skeleton;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .inner {
      font-size: $px12;

      .followed > button {
        &.follow, &.following {
          padding: $px8 $px12;
        }
      }
    }
  }

  @media (max-width: 320px) {
    .inner {
      .info {
        span.img {
          width: $px30 !important;
          height: $px30 !important;
        }
      }
    }
  }
}
</style>